<template>
    <b-card v-if="users" no-body class="card-company-table">
        <b-table :items="users" responsive :fields="fields" class="mb-0">

            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <!-- CreatedAt Time -->
            <template #cell(createdAt)="data">
                <span class="font-weight-bolder mb-12">{{
                data.item.createdAt | moment("DD.MM.YYYY hh:mm")
                }}</span>
            </template>

            <!-- Status -->
            <template #cell(isActive)="data">
                <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                    {{data.item.isActive?'AKTİF':'PASİF'}}
                </b-badge>
            </template>

            <!-- Start Time -->
            <template #cell(startDate)="data">
                <span class="font-weight-bolder mb-12">{{
                data.item.startDate | moment("DD.MM.YYYY hh:mm")
                }}</span>
            </template>

            <!-- End Time -->
            <template #cell(endDate)="data">
                <span class="font-weight-bolder mb-12">{{
                data.item.endDate | moment("DD.MM.YYYY hh:mm")
                }}</span>
            </template>
            <!-- Actions -->
            <template #cell(actions)="data">
                <b-button variant="flat-primary" :to="{ name: 'exam-lesson-page', params: { id: data.item.id } }">
                    <feather-icon icon="LinkIcon" class="mr-50" />
                    <span class="align-middle">Dersler</span>
                </b-button>
                <b-button variant="flat-warning" @click="editExam(data.item)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Düzenle</span>
                </b-button>
                <b-button variant="flat-danger" @click="deleteExam(data.item)">
                    <feather-icon icon="DeleteIcon" class="mr-50" />
                    <span class="align-middle">Sil</span>
                </b-button>
            </template>

        </b-table>

        <!--Open Add Exam-->
        <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addExamPanel">
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">Yeni Deneme Sınavı Ekle</h5>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <!-- Body -->
                <b-form class="p-2" @submit.prevent>
                    <b-form-group label="Kodu" label-for="name">
                        <b-form-input name="firstName" id="name" type="text" v-model="addExamRequest.code" />
                    </b-form-group>
                    <b-form-group label="Name" label-for="name">
                        <b-form-input name="firstName" id="name" type="text" v-model="addExamRequest.Name" />
                    </b-form-group>
                    <b-form-group label="Açıklama" label-for="name">
                        <b-form-input name="firstName" id="name" type="text" v-model="addExamRequest.definition" />
                    </b-form-group>
                    <b-form-group label="Başlangıç Tarihi" label-for="name">
                        <flat-pickr v-model="addExamRequest.startDate" class="form-control"
                            :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i'}" />
                    </b-form-group>
                    <b-form-group label="Bitiş Tarihi" label-for="name">
                        <flat-pickr v-model="addExamRequest.endDate" class="form-control"
                            :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i' }" />
                    </b-form-group>
                    <b-form-group label="Test Süresi" label-for="name">
                        <b-form-input name="firstName" id="name" type="number" v-model="addExamRequest.duration" />
                    </b-form-group>
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" @click="addExam">
                            Ekle
                        </b-button>
                        <b-button variant="outline-secondary" @click="hide">
                            Vazgeç
                        </b-button>
                    </div>
                </b-form>
            </template>
        </b-sidebar>

        <!--Open Edit Exam-->
        <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right
            v-model="editExamPanel">
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">Test Düzenle</h5>

                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>
                <!-- Body -->
                <b-form class="p-2" @submit.prevent>
                    <b-form-group label="Kodu" label-for="name">
                        <b-form-input name="firstName" id="name" type="text" v-model="editExamRequest.code" />
                    </b-form-group>
                    <b-form-group label="Name" label-for="name">
                        <b-form-input name="firstName" id="name" type="text" v-model="editExamRequest.name" />
                    </b-form-group>
                    <b-form-group label="Açıklama" label-for="name">
                        <b-form-input name="firstName" id="name" type="text" v-model="editExamRequest.definition" />
                    </b-form-group>

                    <b-form-group label="İlk Sınav" label-for="name">
                        <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                            :reduce="(exam) => exam.id" :options="users" v-model="editExamRequest.firstExamId" />
                    </b-form-group>

                    <b-form-group label="Başlangıç Tarihi" label-for="name">
                        <flat-pickr v-model="editExamRequest.startDate" class="form-control"
                            :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i' }" />
                    </b-form-group>
                    <b-form-group label="Bitiş Tarihi" label-for="name">
                        <flat-pickr v-model="editExamRequest.endDate" class="form-control"
                            :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i' }" />
                    </b-form-group>
                    <b-form-group label="Test Süresi" label-for="name">
                        <b-form-input name="firstName" id="name" type="number" v-model="editExamRequest.duration" />
                    </b-form-group>
                    <b-form-group label="Yayınlama" label-for="from">
                        <b-form-checkbox v-model="editExamRequest.isActive">
                            Öğrencilere Yayınlansın
                        </b-form-checkbox>
                    </b-form-group>
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" @click="updateExam">
                            Güncelle
                        </b-button>
                        <b-button variant="outline-secondary" @click="hide">
                            Vazgeç
                        </b-button>
                    </div>
                </b-form>
            </template>

        </b-sidebar>

    </b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        flatPickr,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Kod' },
                { key: 'name', label: 'Başlık' },
                { key: 'definition', label: 'Açıklama' },
                { key: 'class.name', label: 'Sınıf' },
                { key: 'startDate', label: 'Başlangıç Tarihi' },
                { key: 'duration', label: 'Sınav Süresi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'actions', label: 'Detaylar' },
            ],
            users: [],
            addExamPanel: false,
            editExamPanel: false,
            addExamRequest: {
                code: '',
                name: '',
                definition: '',
                startDate: '',
                endDate: '',
                duration: 0
            },
            editExamRequest: {
                firstExamId: '',
                examId: 0,
                code: '',
                name: '',
                definition: '',
                startDate: '',
                endDate: '',
                duration: 0,
                isActive: false
            }
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addExamPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Exam/Exams");
            this.users = users.data.data;
        },
        addExam() {
            var request = this.addExamRequest;
            request.duration = parseInt(request.duration);
            this.$http.post("Exam/AddExam", request).then((data) => {
                this.getData();
                this.addExamPanel = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'İşlem',
                        icon: 'BellIcon',
                        text: "İşlem Başarılı Test Oluşturuldu",
                        variant: 'success'
                    },
                });
            })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateExam() {
            var request = this.editExamRequest;
            request.duration = parseInt(request.duration);
            this.$http.put("Exam/UpdateExam", request).then((data) => {
                this.getData();
                this.editExamPanel = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'İşlem',
                        icon: 'BellIcon',
                        text: "İşlem Başarılı Test Güncellendi",
                        variant: 'success'
                    },
                });
            })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        editExam(data) {
            if (data.firstExam == null) {
                data.firstExam = new Object();
                data.firstExam.id = "";
            }
            this.editExamRequest = {
                examId: data.id,
                code: data.code,
                name: data.name,
                definition: data.definition,
                startDate: data.startDate,
                endDate: data.endDate,
                duration: data.duration,
                isActive: data.isActive,
                firstExamId: data.firstExam.id
            }
            this.editExamPanel = true;
        },
        deleteExam(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.$http.delete("Exam/DeleteExam/" + data.id);
                        this.getData();
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
